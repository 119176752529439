import Image from 'next/image';
import Link from 'next/link';
import ContentFacesAndReadingtime from './ContentFacesAndReadingtime';

type ContentCardEvidenceProps = Content;

const ContentCardEvidence = ({
  title,
  readingTime,
  sectionsFace,
  image,
  canonical,
}: ContentCardEvidenceProps) => {
  return (
    <div className="relative bg-white rounded-[15px] p-2 h-full flex flex-col z-0 overflow-hidden">
      <div className="flex flex-col mt-auto px-2 pb-6 lg:pb-10 md:px-14 md:py-16">
        <div className="p-4 items-stretch gap-[18px] hidden lg:flex">
          <ContentFacesAndReadingtime
            faces={sectionsFace}
            readingTime={readingTime}
            color="white"
          />
        </div>
        <div className="px-4 pb-4">
          <Link href={`/learning-area/${canonical}`}>
            <span className="block text-darkBrown lg:text-white text-[20px] leading-6 line-clamp-5 md:line-clamp-6 md:text-[32px] md:leading-9 hover:underline">
              {title}
            </span>
          </Link>
        </div>
      </div>
      <div className="absolute top-0 left-0 right-0 bottom-0 -z-[1]">
        <Link href={`/learning-area/${canonical}`} className="lg:hidden">
          <Image
            alt={`thumbnail | ${title}`}
            src={
              image
                ? process.env.NEXT_PUBLIC_ASSET_URL + image
                : '/assets/content-placeholder.png'
            }
            fill
            priority
            className="object-cover"
          />
        </Link>

        <Image
          alt={`thumbnail | ${title}`}
          src={
            image
              ? process.env.NEXT_PUBLIC_ASSET_URL + image
              : '/assets/content-placeholder.png'
          }
          fill
          priority
          className="object-cover hidden lg:block"
        />
      </div>
      <div className="pointer-events-none absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-t from-superLightBrown lg:from-brown to-transparent -z-[1]"></div>
    </div>
  );
};

export default ContentCardEvidence;
