import React from 'react';
import Image from 'next/image';
import cx from 'classnames';

import CurriculumRoadmapCard from './CurriculumRoadmapCard';
import useResponsive from '../../hooks/useResponsive';

type CurriculumRoadmapProps = {
  curricula?: Curriculum[];
};

const positions = [
  'top-[200px] left-[450px]',
  'top-[370px] left-[570px]',
  'top-[570px] left-[575px]',
  'top-[760px] left-[470px]',
];

const arrowPositions = [
  'top-[305px] left-[550px] rotate-[335deg]',
  'top-[495px] left-[598px] rotate-[0deg]',
  'top-[700px] left-[560px] rotate-[25deg]',
  'hidden',
];

export default function CurriculumRoadmap({
  curricula = [],
}: CurriculumRoadmapProps) {
  const {lg} = useResponsive();

  if (lg) {
    return (
      <div className="relative mt-10">
        <div className="relative min-h-[1100px] max-w-[1000px]">
          <Image
            src="/assets/curriculum.png"
            fill
            alt="Immagine fondo curriculum"
            className="object-left object-contain overflow-visible translate-x-[-10px]"
          />
        </div>

        <div className="absolute top-16 text-center left-0 right-0">
          <h2 className="font-title text-2xl mb-2">
            Center of Excellence - Il tuo percorso
          </h2>

          <p className="text-lg">
            Clicca su un curriculum per scoprire i suoi corsi
          </p>
        </div>

        {curricula.map((curriculum, i) =>
          positions[i] ? (
            <span key={curriculum.id}>
              <CurriculumRoadmapCard
                curriculumNumber={i + 1}
                curriculum={curriculum}
                className={cx('absolute translate-x-[-10px]', positions[i])}
              />

              <Image
                width={46}
                height={46}
                src="/assets/icons/arrow-rounded.svg"
                alt=""
                className={cx(
                  'absolute translate-x-[-10px]',
                  arrowPositions[i]
                )}
              />
            </span>
          ) : null
        )}
      </div>
    );
  }

  return (
    <div className="px-2">
      <h2 className="font-title text-center text-xl mt-14 mb-3/">
        Center of Excellence -<br />
        Il tuo percorso
      </h2>

      <p className="text-center mb-12">
        Clicca su un curriculum per scoprire i suoi corsi
      </p>

      {curricula.map((curriculum, i) => (
        <React.Fragment key={curriculum.id}>
          <CurriculumRoadmapCard
            curriculumNumber={i + 1}
            curriculum={curriculum}
          />

          <Image
            width={19}
            height={10}
            src="/assets/icons/arrow.svg"
            alt=""
            className="my-4 mx-auto last:hidden"
          />
        </React.Fragment>
      ))}
    </div>
  );
}
