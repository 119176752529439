function getFacezones(a: string[]) {
  let t: string[] = [];
  let clone = [...a];
  let facezones = [
    {
      label: 'Terzo Superiore',
      value: 'sup',
    },
    {
      label: 'Terzo Medio',
      value: 'mid',
    },
    {
      label: 'Terzo Inferiore',
      value: 'inf',
    },
    {
      label: 'Volto Intero',
      value: 'all',
    },
  ];

  if (a.includes('full')) {
    t = ['Volto Intero'];
  } else {
    facezones.forEach((e) => {
      if (clone.includes(e.value)) {
        t.push(e.label);
      }
    });
  }

  const titles = t.toString().replaceAll(',', ', ');
  const canonical = a.includes('full')
    ? 'full'
    : clone
        .sort((a, b) => (a > b ? -1 : 1))
        .toString()
        .toLowerCase()
        .replaceAll(',', '-');

  return [canonical, titles];
}

export default getFacezones;
