import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

type CircluarProgressProps = {
  value: number;
  total: number;
  large?: boolean;
  dim?: number;
};

export default function CircluarProgress({
  value,
  total,
  large,
  dim = 100,
}: CircluarProgressProps) {
  return (
    <div style={{width: dim, height: dim, flexShrink: 0}}>
      <CircularProgressbar
        value={(value * 100) / (total || 1)} //avoid divide by zero
        text={`${value}/${total}`}
        strokeWidth={5}
        className="font-light"
        styles={buildStyles({
          // Text size
          textSize: '32px',
          // Colors
          pathColor: '#3D251F',
          textColor: '#3D251F',
          trailColor: '#F2D9D0',
          backgroundColor: '#3D251F',
        })}
      />
    </div>
  );
}
