import {useEffect, useRef, useState} from 'react';
import ContentCardEvidence from './ContentCardEvidence';
import cx from 'classnames';

type ContentEvidenceProps = {
  list: Content[];
  hFixed?: boolean;
};

const ContentEvidenceSlider = ({
  list,
  hFixed = false,
}: ContentEvidenceProps) => {
  const stopAnimation = useRef(false);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const delay = 4000;

    let timer = setInterval(() => {
      if (stopAnimation.current) {
        return;
      }

      if (index + 1 < list.length) {
        setIndex(index + 1);
      }

      if (index + 1 === list.length) {
        setIndex(0);
      }
    }, delay);

    return () => clearInterval(timer);
  }, [index, list.length]);

  return (
    <div
      onMouseOver={() => (stopAnimation.current = true)}
      onMouseLeave={() => (stopAnimation.current = false)}
      className={cx([
        'relative h-full min-h-[300px]',
        hFixed ? 'h-[300px] md:h-[584px]' : '',
      ])}
    >
      {list.length === 0 && (
        <div
          className={cx([
            'relative h-full min-h-[300px] border rounded-2xl border-lightBrown flex justify-center items-center text-sm text-grey p-2',
            hFixed ? 'h-[300px] md:h-[584px]' : '',
          ])}
        >
          Nessun contenuto in evidenza.
        </div>
      )}
      {list.length > 0 &&
        list.map((content, i) => {
          return (
            <div
              key={i}
              className={cx(
                'absolute top-0 right-0 bottom-0 left-0 transition-all duration-1000 ease-in-out',
                {
                  'visible opacity-100': i === index,
                  'invisible opacity-0': i !== index,
                }
              )}
            >
              <div className="absolute top-0 right-0 bottom-0 left-0">
                <ContentCardEvidence key={i} {...content} />
              </div>
              <div className="absolute bottom-4 md:bottom-8 left-1/2 translate-x-[-50%] flex gap-4">
                {list.map((_, i) => {
                  return (
                    <span
                      key={i}
                      className={cx(
                        'w-20 h-1 bg-[#F2D9D0] lg:lightBrown rounded-2xl transition-all duration-500',
                        {'bg-[#c49387] lg:bg-white': i === index}
                      )}
                    ></span>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ContentEvidenceSlider;
