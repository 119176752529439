import {useMemo} from 'react';
import cx from 'classnames';

import useResponsive from '../../hooks/useResponsive';
import Button from '../Button';
import CircluarProgress from '../CircularProgress';
import Link from 'next/link';
import CurriculumRecapCard from './CurriculumRecapCard';
import {
  totalCompletedRequiredCourses,
  totalRequiredCourses,
} from '../../utils/courses';
import Image from 'next/image';

type CurriculumRecapProps = {
  id: Curriculum['id'];
  title: string;
  courses: Course[];
  isSmall?: boolean;
};

export default function CurriculumRecap({
  id,
  title,
  courses,
  isSmall,
}: CurriculumRecapProps) {
  const {md, lg} = useResponsive();

  const {
    hasCourseWithStatus,
    displayedCourses,
    numCoursesCompleted,
    numCourses,
  } = useMemo(() => {
    const hasCourseWithStatus = courses.some(
      (course) => course.status === 'pending' || course.status === 'approved'
    );

    let displayedCourses: Course[] = [];

    if (hasCourseWithStatus) {
      displayedCourses = courses
        .filter(
          (course) =>
            course.status === 'pending' || course.status === 'approved'
        )
        .slice(0, lg ? 2 : 1);
    } else {
      displayedCourses = courses
        .filter((course) => course.status !== 'completed')
        .slice(0, lg ? 2 : 1);
    }

    const numCoursesCompleted = totalCompletedRequiredCourses(courses);
    const numCourses = totalRequiredCourses(courses);

    return {
      hasCourseWithStatus,
      displayedCourses,
      numCoursesCompleted,
      numCourses,
    };
  }, [courses, lg]);

  const listText = hasCourseWithStatus
    ? 'I tuoi prossimi  corsi'
    : 'Scegli corsi';

  return (
    <div className="flex w-full flex-col lg:rounded-[25px] lg:shadow-curriculumCard lg:bg-white lg:pt-8">
      <Link
        href={`/curriculum/${id}`}
        className="mb-6 px-2 lg:px-8 flex items-start gap-2 justify-between"
      >
        <div>
          <h2 className="m-0 mb-1 text-xl font-medium text-white lg:text-darkBrown">
            Il tuo Curriculum
          </h2>
          <p className="text-base text-white lg:text-darkBrown">
            Iscriviti ai corsi e completa tutti i Curriculum .
          </p>
        </div>
        <div className="flex items-center justify-center w-6 h-6 rounded-full bg-white lg:bg-brown">
          <Image
            src="/assets/icons/arrow-white.svg"
            width={5}
            height={9}
            alt="freccia"
            className="hidden lg:block"
          />

          <Image
            src="/assets/icons/back-arrow.svg"
            width={5}
            height={9}
            alt="freccia"
            className="rotate-180 lg:hidden"
          />
        </div>
      </Link>

      <Link href={`/curriculum/${id}`} className="relative lg:px-4">
        <div className="bg-brown lg:bg-white">
          <div
            className={cx(
              'relative rounded-2xl bg-white bg-brown-rgba box-content bg-[url("/assets/background-02.png")] bg-center bg-cover'
            )}
          >
            <div className={cx('flex p-8 items-center lg:h-[163px]')}>
              <CircluarProgress
                value={numCoursesCompleted}
                total={numCourses}
                dim={md ? 100 : 65}
              />

              <div className="ml-4">
                <p className="font-title text-lg font-beatrice lg:text-2xl">
                  {title}
                </p>
                <p className="text-sm md:text-base">
                  {numCourses - numCoursesCompleted} corsi per passare al
                  curriculum successivo
                </p>
              </div>
            </div>
          </div>
        </div>
      </Link>

      <div className="relative hidden flex-col mt-6 pl-[27px] pr-[19px] rounded-b-[25px] h-full pb-8 lg:flex">
        <p className="mt-2 mb-2 ml-6 font-medium">{listText}</p>
        <div className="flex-1">
          {displayedCourses.map((course) => (
            <CurriculumRecapCard key={course.courseId} {...course} />
          ))}
        </div>
        <div
          className={cx([
            'flex w-full justify-center rounded-b-[25px]',
            isSmall &&
              'absolute bottom-0 left-0 bg-gradient-to-t from-[white] to-[transparent]',
          ])}
        >
          <Link
            href={`/curriculum/${id}`}
            className={cx([
              'self-stretch md:self-center mt-6',
              isSmall && 'mb-8',
            ])}
          >
            <Button variant="dark" className="w-full md:w-[230px]">
              Apri il Curriculum
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
