import {useCallback, useMemo} from 'react';
import Image from 'next/image';

import useResponsive from '../../hooks/useResponsive';
import EnrollmentStatus from '../EnrollmentStatus';
import {formatDateLong} from '../../utils/dates';

type CurriculumRecapCardProps = Course & {
  showStatus?: boolean;
  className?: string;
  showRoadmap?: boolean;
};

export default function CurriculumRecapCard(props: CurriculumRecapCardProps) {
  const {md} = useResponsive();
  const dateText = useMemo(() => {
    if (props.sessions.length === 0) {
      return 'Nessuna data disponibile';
    }

    //TODO: potentially sort if data isnt already
    if (props.sessions.length === 1) {
      return formatDateLong(props.sessions[0].dateStart);
    }

    return `${formatDateLong(props.sessions[0].dateStart)} + altre ${
      props.sessions.length - 1
    } date`;
  }, [props.sessions]);

  const renderEnrollmentButton = useCallback(() => {
    if (props.status === 'pending' || props.status === 'approved') {
      return (
        <EnrollmentStatus
          status={props.status}
          bgHidden
          className="text-sm gap-x-1.5"
        />
      );
    }

    if (props.status === 'completed') {
      return <EnrollmentStatus status={props.status} bgHidden />;
    }
  }, [props.status]);

  return (
    <div className="p-2 flex gap-x-4">
      <div className="relative shrink-0 w-[64px] h-[64px] rounded-2xl overflow-hidden">
        <Image
          src={process.env.NEXT_PUBLIC_ASSET_URL + props.thumbnail}
          alt=""
          fill
          className="rounded-[15px] object-cover"
        />
      </div>

      <div className="flex-1 flex flex-col justify-center">
        <div className="mb-2 flex justify-between items-end">
          <p className="mb-[1px] text-brown text-sm font-regular">{dateText}</p>

          {/* {md && renderEnrollmentButton()} */}
        </div>
        <strong className="font-medium text-sm line-clamp-2">
          {props.title}
        </strong>
      </div>
    </div>
  );
}
