import Image from 'next/image';
import cx from 'classnames';

type ContentBadgeProps = {
  label: string;
  icon: string;
  color?: 'grey' | 'white';
  short?: boolean;
};

const ContentBadge = ({
  label,
  icon,
  color = 'grey',
  short,
}: ContentBadgeProps) => {
  return (
    <div className="flex items-start gap-1">
      <Image src={icon} width={14} height={14} alt={label} />
      <span
        title={label}
        className={cx(
          'block text-[11px] leading-3 line-clamp-1',
          [`text-${color}`],
          {
            'max-w-[90px]': short,
          }
        )}
      >
        {label}
      </span>
    </div>
  );
};

export default ContentBadge;
