import getFacezone from '../../utils/getFacezones';
import ContentBadge from './ContentBadge';

type ContentFacesAndReadingtimeProps = {
  faces: string[];
  readingTime?: number;
  color?: 'white' | 'grey';
  short?: boolean;
};

const ContentFacesAndReadingtime = ({
  faces,
  readingTime,
  color,
  short,
}: ContentFacesAndReadingtimeProps) => {
  const [faceCanonicals, faceTitles] = getFacezone(faces);

  return (
    <>
      {faces.length > 0 && (
        <ContentBadge
          color={color}
          label={faceTitles}
          short={short}
          icon={`/assets/icons/facezones/face-${faceCanonicals}${
            color ? '-' + color : ''
          }.svg`}
        />
      )}
      {faces.length > 0 &&
        readingTime &&
        (color === 'white' ? (
          <div className="w-[1px] bg-white h-auto"></div>
        ) : (
          <div className="w-[1px] bg-grey h-auto"></div>
        ))}
      {readingTime && (
        <ContentBadge
          color={color}
          label={readingTime + ' min'}
          icon={`/assets/icons/clock-${color ? color : 'grey'}.svg`}
        />
      )}
    </>
  );
};

export default ContentFacesAndReadingtime;
