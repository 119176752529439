import {useCallback, useMemo, useState} from 'react';
import {useRouter} from 'next/router';
import cx from 'classnames';
import Image from 'next/image';
import {useRequiredUser} from '../../context/UserContext';
import useResponsive from '../../hooks/useResponsive';

type CurriculumRoadmapCardProps = {
  curriculum: Curriculum;
  curriculumNumber: number;
  className?: string;
};

export default function CurriculumRoadmapCard(
  props: CurriculumRoadmapCardProps
) {
  const {push} = useRouter();
  const {lg} = useResponsive();
  const {user} = useRequiredUser();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const hasCourses = props.curriculum.numCourses > 0;
  const isCompleted =
    props.curriculum.numCoursesCompleted === props.curriculum.numCourses;
  const isActive = props.curriculum.id === user.curriculum.id;

  const {icon, text} = useMemo(() => {
    if (isCompleted && hasCourses) {
      return {
        icon: (
          <Image
            src="/assets/icons/curriculum-completed.svg"
            width={39}
            height={39}
            alt=""
            className="-translate-x-1"
          />
        ),
        text: null,
      };
    }

    if (
      (!isActive && props.curriculum.numCoursesCompleted === 0) ||
      !hasCourses
    ) {
      return {
        icon: (
          <Image
            src="/assets/icons/curriculum-locked.svg"
            width={28}
            height={40}
            alt=""
          />
        ),
        text: null,
      };
    }

    return {
      icon: (
        <p className="text-xl font-light -translate-x-[4px]">
          {props.curriculum.numCoursesCompleted}/{props.curriculum.numCourses}
        </p>
      ),
      text: `${
        props.curriculum.numCourses - props.curriculum.numCoursesCompleted
      } corsi per completare il Curriculum`,
    };
  }, [
    hasCourses,
    isActive,
    isCompleted,
    props.curriculum.numCourses,
    props.curriculum.numCoursesCompleted,
  ]);

  if (lg) {
    return (
      <div className={props.className}>
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => push(`/curriculum/${props.curriculum.id}`)}
          className={cx(
            'cursor-pointer',
            'relative rounded-[20px] flex items-center pl-24 pr-4 gap-x-4 min-h-[96px] py-3 overflow-hidden',
            "after:content-[''] after:absolute after:left-0 after:transition-all",
            {
              'after:bg-brown after:w-20 after:h-20 after:rounded-full':
                !isHovered,
              'after:bg-white after:w-full after:h-full after:scale-[1.2]':
                isHovered,
              'after:bg-white': !isActive,
            }
          )}
        >
          <div
            className={cx('absolute left-[26px] z-20', {
              'text-white': isActive,
              'text-brown': isHovered,
            })}
          >
            {icon}
          </div>

          <div className="text-superDarkBrown z-10 mr-4">
            <p className="font-title text-xl">{props.curriculum.title}</p>
            {isHovered && text && <p className="text-sm">{text}</p>}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div
        onClick={() => push(`/curriculum/${props.curriculum.id}`)}
        className={cx(
          'cursor-pointer',
          'rounded-[20px] flex items-center gap-x-5 p-4 w-full bg-white'
        )}
      >
        <div className="text-brown">{icon}</div>

        <div className="text-superDarkBrown z-10 mr-4">
          <p className="font-title text-base">{props.curriculum.title}</p>
          <p className="text-sm">{text}</p>
        </div>
      </div>
    </div>
  );
}
